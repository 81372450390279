export enum Registre {
    MARTIAL = 'MARTIAL',
    EPIQUE = 'EPIQUE',
    LYRIQUE = 'LYRIQUE',
    BURLESQUE = 'BURLESQUE',
    IRONIQUE = 'IRONIQUE',
    PESSIMISTE = 'PESSIMISTE',
    DRAMATIQUE = 'DRAMATIQUE',
    REALISTE = 'REALISTE',
    SATIRIQUE = 'SATIRIQUE',
    JOURNALISTIQUE = 'JOURNALISTIQUE',
    MERVEILLEUX = 'MERVEILLEUX',
    PARODIQUE = 'PARODIQUE',
    DELIBERATIF = 'DELIBERATIF',
    DIDACTIQUE = 'DIDACTIQUE',
    POLEMIQUE = 'POLEMIQUE',
    PATHETIQUE = 'PATHETIQUE',
    TRAGIQUE = 'TRAGIQUE',
    EPIDICTIQUE = 'EPIDICTIQUE',
    SOLENNEL = 'SOLENNEL',
    SERIEUX = 'SERIEUX',
    ORATOIRE = 'ORATOIRE',
    GROTESQUE = 'GROTESQUE',
    FANTASTIQUE = 'FANTASTIQUE',
    MYSTIQUE = 'MYSTIQUE',
    COMIQUE = 'COMIQUE',
    HUMORISTIQUE = 'HUMORISTIQUE',
    INFORMATIF = 'INFORMATIF',
    SCIENTIFIQUE = 'SCIENTIFIQUE',
    FARCESQUE = 'FARCESQUE',
    LUDIQUE = 'LUDIQUE',
    HORRIFIQUE = 'HORRIFIQUE',
    RELIGIEUX = 'RELIGIEUX',
}

interface RegistreObj {
    label: string
}

export function registre(registre: Registre): RegistreObj {
    switch (registre) {
        case Registre.MARTIAL:
            return {label: 'Martial'}
        case Registre.EPIQUE:
            return {label: 'Épique'}
        case Registre.LYRIQUE:
            return {label: 'Lyrique'}
        case Registre.BURLESQUE:
            return {label: 'Burlesque'}
        case Registre.IRONIQUE:
            return {label: 'Ironique'}
        case Registre.PESSIMISTE:
            return {label: 'Pessimiste'}
        case Registre.DRAMATIQUE:
            return {label: 'Dramatique'}
        case Registre.REALISTE:
            return {label: 'Réaliste'}
        case Registre.SATIRIQUE:
            return {label: 'Satirique'}
        case Registre.JOURNALISTIQUE:
            return {label: 'Journalistique'}
        case Registre.MERVEILLEUX:
            return {label: 'Merveilleux'}
        case Registre.PARODIQUE:
            return {label: 'Parodique'}
        case Registre.DELIBERATIF:
            return {label: 'Délibératif'}
        case Registre.DIDACTIQUE:
            return {label: 'Didactique'}
        case Registre.POLEMIQUE:
            return {label: 'Polémique'}
        case Registre.PATHETIQUE:
            return {label: 'Pathétique'}
        case Registre.TRAGIQUE:
            return {label: 'Tragique'}
        case Registre.EPIDICTIQUE:
            return {label: 'Épidictique'}
        case Registre.SOLENNEL:
            return {label: 'Solennel'}
        case Registre.SERIEUX:
            return {label: 'Sérieux'}
        case Registre.ORATOIRE:
            return {label: 'Oratoire'}
        case Registre.GROTESQUE:
            return {label: 'Grotesque'}
        case Registre.FANTASTIQUE:
            return {label: 'Fantastique'}
        case Registre.MYSTIQUE:
            return {label: 'Mystique'}
        case Registre.COMIQUE:
            return {label: 'Comique'}
        case Registre.HUMORISTIQUE:
            return {label: 'Humoristique'}
        case Registre.INFORMATIF:
            return {label: 'Informatif'}
        case Registre.SCIENTIFIQUE:
            return {label: 'Scientifique'}
        case Registre.FARCESQUE:
            return {label: 'Farcesque'}
        case Registre.LUDIQUE:
            return {label: 'Ludique'}
        case Registre.HORRIFIQUE:
            return {label: 'Horrifique'}
        case Registre.RELIGIEUX:
            return {label: 'Religieux'}
    }
}
import nodeSlugify from "slugify";

export function trimmedOrNull(s: string | null): string | null {
    s = s ? s.trim() : null;
    return s ? s : null;
}

export function trimmedLowerCaseOrNull(s: string | null): string | null {
    s = trimmedOrNull(s);
    return s ? s.toLowerCase() : null;
}

nodeSlugify.extend({
    '\'': '-',
    '’': '-',
    '-': '-',
})

export function slugify(s: string): string {
    return nodeSlugify(s, {
        lower: true,
        remove: /[*+~.,()"”“«»!?:@°]/g
    })
}
import {Column, CreateDateColumn, Entity, JoinColumn, ManyToOne, PrimaryColumn, UpdateDateColumn} from 'typeorm'
import {Genre} from './Genre'
import {EntreeProgramme} from './EntreeProgramme'
import {Narration} from './Narration'
import {Focalisation} from './Focalisation'
import {Temps} from './Temps'
import {TypeDiscours} from './TypeDiscours'
import {Registre} from './Registre'
import {Mouvement} from './Mouvement'
import {NanoId} from './Ids'
import {slugify} from '../StringUtils'
import User from './User'
import {identifier, object, serializable} from 'serializr'

@Entity()
export default class Text {
    @PrimaryColumn('char', {name: 'id', length: 16})
    @serializable(identifier())
    private _id!: NanoId
    @Column('text', {name: 'title'})
    private _title!: string
    @Column('text', {name: 'entrees_programmes', array: true})
    private _entreesProgrammes: EntreeProgramme[] = []
    @Column('text', {name: 'narration'})
    private _narration: Narration = Narration.SANS_OBJET
    @Column('text', {name: 'focalisation'})
    private _focalisation: Focalisation = Focalisation.SANS_OBJET
    @Column('text', {name: 'genres', array: true})
    private _genres: Genre[] = []
    @Column('text', {name: 'place_dans_oeuvre', nullable: true})
    private _placeDansOeuvre: string | null = null
    @Column('text', {name: 'themes', array: true})
    private _themes: string[] = []
    @Column('text', {name: 'temps_dominants', array: true})
    private _tempsDominants: Temps[] = []
    @Column('text', {name: 'types_discours', array: true})
    private _typesDiscours: TypeDiscours[] = []
    @Column('text', {name: 'notions_litteraires', array: true})
    private _notionsLitteraires: string[] = []
    @Column('text', {name: 'registres', array: true})
    private _registres: Registre[] = []
    @Column('text', {name: 'mouvements', array: true})
    private _mouvements: Mouvement[] = []
    @Column('text', {name: 'text_content'})
    private _textContent: string = ''
    @Column('text', {name: 'html_content'})
    private _htmlContent: string = ''
    @Column('json', {name: 'delta_content'})
    private _deltaContent: any = {}
    @Column('text', {name: 'description', nullable: true})
    private _description: string | null = null
    @Column('text', {name: 'resume', nullable: true})
    private _resume: string | null = null
    @CreateDateColumn({name: 'created_at'})
    private _createdAt: Date = new Date()
    @UpdateDateColumn({name: 'updated_at'})
    private _updatedAt: Date = new Date()
    @Column('integer', {name: 'siecle', nullable: true})
    private _siecle: number | null = null
    @Column('integer', {name: 'annee_edition', nullable: true})
    private _anneeEdition: number | null = null
    @Column('text', {name: 'resonnance_avec', nullable: true})
    private _resonnanceAvec: string | null = null
    @Column('text', {name: 'auteur', nullable: true})
    private _auteur: string | null = null
    @Column('text', {name: 'oeuvre', nullable: true})
    private _oeuvre: string | null = null
    @ManyToOne(() => User, {nullable: false})
    @JoinColumn({name: 'contributor_id'})
    @serializable(object(User))
    private _contributor!: User
    @Column('timestamptz', {name: 'deleted_at', nullable: true})
    private _deletedAt: Date | null = null

    get id(): NanoId {
        return this._id
    }

    set id(value: NanoId) {
        this._id = value
    }

    get title(): string {
        return this._title
    }

    set title(value: string) {
        this._title = value
    }

    get slug(): string {
        return slugify(this.title)
    }

    get entreesProgrammes(): EntreeProgramme[] {
        return this._entreesProgrammes
    }

    set entreesProgrammes(value: EntreeProgramme[]) {
        this._entreesProgrammes = value
    }

    get narration(): Narration {
        return this._narration
    }

    set narration(value: Narration) {
        this._narration = value
    }

    get focalisation(): Focalisation {
        return this._focalisation
    }

    set focalisation(value: Focalisation) {
        this._focalisation = value
    }

    get genres(): Genre[] {
        return this._genres
    }

    set genres(value: Genre[]) {
        this._genres = value
    }

    get placeDansOeuvre(): string | null {
        return this._placeDansOeuvre
    }

    set placeDansOeuvre(value: string | null) {
        this._placeDansOeuvre = value
    }

    get themes(): string[] {
        return this._themes
    }

    set themes(value: string[]) {
        this._themes = value
    }

    get tempsDominants(): Temps[] {
        return this._tempsDominants
    }

    set tempsDominants(value: Temps[]) {
        this._tempsDominants = value
    }

    get typesDiscours(): TypeDiscours[] {
        return this._typesDiscours
    }

    set typesDiscours(value: TypeDiscours[]) {
        this._typesDiscours = value
    }

    get notionsLitteraires(): string[] {
        return this._notionsLitteraires
    }

    set notionsLitteraires(value: string[]) {
        this._notionsLitteraires = value
    }

    get registres(): Registre[] {
        return this._registres
    }

    set registres(value: Registre[]) {
        this._registres = value
    }

    get mouvements(): Mouvement[] {
        return this._mouvements
    }

    set mouvements(value: Mouvement[]) {
        this._mouvements = value
    }

    get textContent(): string {
        return this._textContent
    }

    set textContent(value: string) {
        this._textContent = value
    }

    get htmlContent(): string {
        return this._htmlContent
    }

    set htmlContent(value: string) {
        this._htmlContent = value
    }

    get deltaContent(): any {
        return this._deltaContent
    }

    set deltaContent(value: any) {
        this._deltaContent = value
    }

    get description(): string | null {
        return this._description
    }

    set description(value: string | null) {
        this._description = value
    }

    get resume(): string | null {
        return this._resume
    }

    set resume(value: string | null) {
        this._resume = value
    }

    get createdAt(): Date {
        return this._createdAt
    }

    set createdAt(value: Date) {
        this._createdAt = value
    }

    get updatedAt(): Date {
        return this._updatedAt
    }

    set updatedAt(value: Date) {
        this._updatedAt = value
    }

    get siecle(): number | null {
        return this._siecle
    }

    set siecle(value: number | null) {
        this._siecle = value
    }

    get anneeEdition(): number | null {
        return this._anneeEdition
    }

    set anneeEdition(value: number | null) {
        this._anneeEdition = value
    }

    get resonnanceAvec(): string | null {
        return this._resonnanceAvec
    }

    set resonnanceAvec(value: string | null) {
        this._resonnanceAvec = value
    }

    get auteur(): string | null {
        return this._auteur
    }

    set auteur(value: string | null) {
        this._auteur = value
    }

    get oeuvre(): string | null {
        return this._oeuvre
    }

    set oeuvre(value: string | null) {
        this._oeuvre = value
    }

    get contributor(): User {
        return this._contributor
    }

    set contributor(value: User) {
        this._contributor = value
    }

    get deletedAt(): Date | null {
        return this._deletedAt
    }

    set deletedAt(value: Date | null) {
        this._deletedAt = value
    }
}
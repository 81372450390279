export enum TypeDiscours {
    NARRATIF = 'NARRATIF',
    ARGUMENTATIF = 'ARGUMENTATIF',
    DESCRIPTIF = 'DESCRIPTIF',
    EXPLICATIF = 'EXPLICATIF',
    DIALOGUE = 'DIALOGUE',
}

interface TypeDiscoursObj {
    label: string
}

export function typeDiscours(typeDiscours: TypeDiscours): TypeDiscoursObj {
    switch (typeDiscours) {
        case TypeDiscours.NARRATIF:
            return {label: 'Narratif'};
        case TypeDiscours.ARGUMENTATIF:
            return {label: 'Argumentatif'};
        case TypeDiscours.DESCRIPTIF:
            return {label: 'Descriptif'};
        case TypeDiscours.EXPLICATIF:
            return {label: 'Explicatif'};
        case TypeDiscours.DIALOGUE:
            return {label: 'Dialogue'};
    }
}
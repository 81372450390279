export enum Mouvement {
    NATIONALISME = 'NATIONALISME',
    POESIE_ENGAGEE = 'POESIE_ENGAGEE',
    OULIPO = 'OULIPO',
    EXISTENTIALISME = 'EXISTENTIALISME',
    FEMINISME = 'FEMINISME',
    LITTERATURE_CONTEMPORAINE = 'LITTERATURE_CONTEMPORAINE',
    ROMANTISME = 'ROMANTISME',
    SYMBOLISME = 'SYMBOLISME',
    PARNASSE = 'PARNASSE',
    LUMIERES = 'LUMIERES',
    MODERNITE = 'MODERNITE',
    ART_POUR_ART = 'ART_POUR_ART',
    CLASSICISME = 'CLASSICISME',
    BAROQUE = 'BAROQUE',
    REALISME = 'REALISME',
    NATURALISME = 'NATURALISME',
    DECADENCE = 'DECADENCE',
    PLEIADE = 'PLEIADE',
    HUMANISME = 'HUMANISME',
    GRANDS_RETHORIQUEUR = 'GRANDS_RETHORIQUEUR',
    SURREALISME = 'SURREALISME',
    ACADEMISME = 'ACADEMISME',
}

interface MouvementObj {
    label: string
}

export function mouvement(mouvement: Mouvement): MouvementObj {
    switch (mouvement) {
        case Mouvement.NATIONALISME:
            return {label: 'Nationalisme'}
        case Mouvement.POESIE_ENGAGEE:
            return {label: 'Poésie engagée'}
        case Mouvement.OULIPO:
            return {label: 'Oulipo'}
        case Mouvement.EXISTENTIALISME:
            return {label: 'Existentialisme'}
        case Mouvement.FEMINISME:
            return {label: 'Féminisme'}
        case Mouvement.LITTERATURE_CONTEMPORAINE:
            return {label: 'Littérature contemporaine'}
        case Mouvement.ROMANTISME:
            return {label: 'Romantisme'}
        case Mouvement.SYMBOLISME:
            return {label: 'Symbolisme'}
        case Mouvement.PARNASSE:
            return {label: 'Parnasse'}
        case Mouvement.LUMIERES:
            return {label: 'Les Lumières'}
        case Mouvement.MODERNITE:
            return {label: 'Modernité'}
        case Mouvement.ART_POUR_ART:
            return {label: 'Art pour l\'art'}
        case Mouvement.CLASSICISME:
            return {label: 'Classicisme'}
        case Mouvement.BAROQUE:
            return {label: 'Baroque'}
        case Mouvement.REALISME:
            return {label: 'Réalisme'}
        case Mouvement.NATURALISME:
            return {label: 'Naturalisme'}
        case Mouvement.DECADENCE:
            return {label: 'Décadence'}
        case Mouvement.PLEIADE:
            return {label: 'Pléiade'}
        case Mouvement.HUMANISME:
            return {label: 'Humanisme'}
        case Mouvement.GRANDS_RETHORIQUEUR:
            return {label: 'Les Grands Rhétoriqueurs'}
        case Mouvement.SURREALISME:
            return {label: 'Surréalisme'}
        case Mouvement.ACADEMISME:
            return {label: 'Académisme'}
    }
}
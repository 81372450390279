import React from 'react'
import ReactDOM from 'react-dom'
import {HeaderComponent} from '../header/HeaderComponent'
import Favicon from '../favicon/favicon.svg'
import {SearchInputs} from '../../server/routes'

(function () {
    const headerElement = document.querySelector<HTMLElement>('.header')
    if (!headerElement)
        return
    const searchInputsData = JSON.parse(document.getElementById('search-inputs-data')?.innerHTML || 'null') as SearchInputs | null
    const postLoginRedirect = headerElement.dataset.postLoginRedirect
    ReactDOM.hydrate(
        <HeaderComponent searchInputs={searchInputsData || undefined}
                         favicon={Favicon}
                         loggedInUser={window.loggedInUser}
                         postLoginRedirect={postLoginRedirect}/>,
        headerElement)
})()

import React, {FunctionComponent, useState} from 'react'
import {routes} from '../../server/routes'
import {Registre} from '../../server/model/Registre'
import {slug} from '../../server/model/Slug'
import {Genre} from '../../server/model/Genre'
import {TypeDiscours} from '../../server/model/TypeDiscours'

type Menus = 'registre' | 'genres-litteraires' | 'dominantes' | 'siecles' | 'auteurs'
export const CategoryMenu: FunctionComponent = () => {
    const [openedMenu, setOpenedMenu] = useState<Menus | null>(null)
    return (
        <div id="category-menu" onMouseLeave={() => setOpenedMenu(null)}>
            <div className="row">
                <a className="col-auto category-heading" href="#" onMouseEnter={() => setOpenedMenu('registre')} id="registre-category-heading">Registres</a>
                <a className="col-auto category-heading" href="#" onMouseEnter={() => setOpenedMenu('genres-litteraires')} id="genres-litteraires-category-heading">Genres littéraires</a>
                <a className="col-auto category-heading" href="#" onMouseEnter={() => setOpenedMenu('dominantes')} id="dominantes-category-heading">Dominantes</a>
                <a className="col-auto category-heading" href="#" onMouseEnter={() => setOpenedMenu('siecles')} id="siecles-category-heading">Siècles</a>
                <a className="col-auto category-heading" href="#" onMouseEnter={() => setOpenedMenu('auteurs')} id="siecles-category-heading">Auteurs</a>
            </div>
            <div style={{position: 'absolute', zIndex: 1, width: '100%', background: 'white', boxShadow: '0 5px 10px var(--color-shadow)'}}>
                <nav aria-labelledby="registre-category-heading" style={{display: openedMenu === 'registre' ? '' : 'none'}}>
                    <ul className="unstyle">
                        <li><a href={routes.getTextsListRegistre.reverse({registreSlug: slug(Registre.DIDACTIQUE)})}>Didactique</a></li>
                        <li><a href={routes.getTextsListRegistre.reverse({registreSlug: slug(Registre.FANTASTIQUE)})}>Fantastique</a></li>
                        <li><a href={routes.getTextsListRegistre.reverse({registreSlug: slug(Registre.IRONIQUE)})}>Ironique</a></li>
                        <li><a href={routes.getTextsListRegistre.reverse({registreSlug: slug(Registre.LYRIQUE)})}>Lyrique</a></li>
                        <li><a href={routes.getTextsListRegistre.reverse({registreSlug: slug(Registre.PATHETIQUE)})}>Pathétique</a></li>
                        <li><a href={routes.getTextsListRegistre.reverse({registreSlug: slug(Registre.POLEMIQUE)})}>Polémique</a></li>
                        <li><a href={routes.getTextsListRegistre.reverse({registreSlug: slug(Registre.SATIRIQUE)})}>Satirique</a></li>
                    </ul>
                </nav>
                <nav aria-labelledby="genres-litteraires-category-heading" style={{display: openedMenu === 'genres-litteraires' ? '' : 'none'}}>
                    <ul className="unstyle">
                        <li><a href={routes.getTextsListGenre.reverse({genreSlug: slug(Genre.ARTICLE)})}>Article</a></li>
                        <li><a href={routes.getTextsListGenre.reverse({genreSlug: slug(Genre.AUTOBIOGRAPHIE)})}>Autobiographie</a></li>
                        <li><a href={routes.getTextsListGenre.reverse({genreSlug: slug(Genre.POESIE)})}>Poésie</a></li>
                        <li><a href={routes.getTextsListGenre.reverse({genreSlug: slug(Genre.ROMAN)})}>Roman</a></li>
                    </ul>
                </nav>
                <nav aria-labelledby="dominantes-category-heading" style={{display: openedMenu === 'dominantes' ? '' : 'none'}}>
                    <ul className="unstyle">
                        <li><a href={routes.getTextsListTypeDiscours.reverse({typeDiscoursSlug: slug(TypeDiscours.ARGUMENTATIF)})}>Argumentation</a></li>
                        <li><a href={routes.getTextsListTypeDiscours.reverse({typeDiscoursSlug: slug(TypeDiscours.DESCRIPTIF)})}>Description</a></li>
                        <li><a href={routes.getTextsListTypeDiscours.reverse({typeDiscoursSlug: slug(TypeDiscours.DIALOGUE)})}>Dialogue</a></li>
                        <li><a href={routes.getTextsListTypeDiscours.reverse({typeDiscoursSlug: slug(TypeDiscours.EXPLICATIF)})}>Explication</a></li>
                        <li><a href={routes.getTextsListTypeDiscours.reverse({typeDiscoursSlug: slug(TypeDiscours.NARRATIF)})}>Narration</a></li>
                    </ul>
                </nav>
                <nav aria-labelledby="siecles-category-heading" style={{display: openedMenu === 'siecles' ? '' : 'none'}}>
                    <ul className="unstyle">
                        <li><a href={routes.getTextsListSiecle.reverse({siecleSlug: 'XIXe'})}>XIX<sup>e</sup> siècle</a></li>
                        <li><a href={routes.getTextsListSiecle.reverse({siecleSlug: 'XXe'})}>XX<sup>e</sup> siècle</a></li>
                        <li><a href={routes.getTextsListSiecle.reverse({siecleSlug: 'XXIe'})}>XXI<sup>e</sup> siècle</a></li>
                    </ul>
                </nav>
                <nav aria-labelledby="siecles-category-heading" style={{display: openedMenu === 'auteurs' ? '' : 'none'}}>
                    <ul className="unstyle">
                        {Object.keys(auteurs).sort().map(l =>
                            <li key={l} className="space">
                                <div className="text-bold">{l}</div>
                                <ul className="unstyle">
                                    {auteurs[l].map(val =>
                                        <li key={val.auteur}><a href={routes.getTextsListAuteur.reverse({auteur: val.auteur})}>{val.nom} {val.prenom}</a></li>
                                    )}
                                </ul>
                            </li>)}
                    </ul>
                </nav>
            </div>
        </div>
    )
}
const auteurs = ([
    {auteur: 'Albert Camus', nom: 'Camus', prenom: 'Albert'},
    {auteur: 'Alliance de la presse d\'information générale', nom: 'Alliance de la presse d\'information'},
    {auteur: 'Colette', nom: 'Colette'},
    {auteur: 'Roald Dahl', nom: 'Dahl', prenom: 'Roald'},
    {auteur: 'Marjane Satrapi', nom: 'Satrapi', prenom: 'Marjane'},
    {auteur: 'Laurent Sacco', nom: 'Sacco', prenom: 'Laurent'},
    {auteur: 'Robert Badinter', nom: 'Badinter', prenom: 'Robert'},
    {auteur: 'Pascal Riché', nom: 'Riché', prenom: 'Pascal'},
    {auteur: 'Antoine Saint-Epondyle', nom: 'Saint-Epondyle', prenom: 'Antoine '},
    {auteur: 'Voltaire', nom: 'Voltaire'},
    {auteur: 'Daniel Cohen', nom: 'Cohen', prenom: 'Daniel'},
    {auteur: 'Albert Cohen', nom: 'Cohen', prenom: 'Albert'},
    {auteur: 'David Caviglioli', nom: 'Caviglioli', prenom: 'David'},
    {auteur: 'Nathalie Quintane', nom: 'Quintane', prenom: 'Nathalie'},
    {auteur: 'Sinik', nom: 'Sinik'},
    {auteur: 'Paul Valéry', nom: 'Valéry', prenom: 'Paul'},
    {auteur: 'David Diop', nom: 'Diop', prenom: 'David'},
    {auteur: 'Guy de Maupassant', nom: 'Maupassant', prenom: 'Guy de'},
    {auteur: 'Maurice Barrès', nom: 'Barrès', prenom: 'Maurice'},
    {auteur: 'Umberto Eco', nom: 'Eco', prenom: 'Umberto'},
    {auteur: 'Jacques Prévert', nom: 'Prévert', prenom: 'Jacques'},
    {auteur: 'Charles Baudelaire', nom: 'Baudelaire', prenom: 'Charles'},
    {auteur: 'Emile Zola', nom: 'Zola', prenom: 'Emile'},
    {auteur: 'Brame Stoker', nom: 'Stoker', prenom: 'Brame'},
    {auteur: 'Robert Desnos', nom: 'Desnos', prenom: 'Robert'},
    {auteur: 'Annie Ernaux', nom: 'Ernaux', prenom: 'Annie'},
    {auteur: 'Pierre Lemaître', nom: 'Lemaître', prenom: 'Pierre'},
    {auteur: 'Michaël Prazan', nom: 'Prazan', prenom: 'Michaël'},
    {auteur: 'Jacques Nerson', nom: 'Nerson', prenom: 'Jacques'},
    {auteur: 'Denis Diderot', nom: 'Diderot', prenom: 'Denis'},
    {auteur: 'Stéphane Mallarmé', nom: 'Mallarmé', prenom: 'Stéphane'},
    {auteur: 'Guillaume Crétin', nom: 'Crétin', prenom: 'Guillaume'},
    {auteur: 'Grégoire Delacourt', nom: 'Delacourt', prenom: 'Grégoire'},
    {auteur: 'Camille Kouchner', nom: 'Kouchner', prenom: 'Camille'},
    {auteur: 'Alice Zeniter', nom: 'Zeniter', prenom: 'Alice'},
    {auteur: 'Jean de La Fontaine', nom: 'La Fontaine', prenom: 'Jean de'},
    {auteur: 'Stendhal', nom: 'Stendhal'},
    {auteur: 'Delphine de Vigan', nom: 'Vigan', prenom: 'Delphine de'},
    {auteur: 'Paul B. Preciado', nom: 'Preciado', prenom: 'Paul B.'},
    {auteur: 'Keny Arkana', nom: 'Arkana', prenom: 'Keny'},
    {auteur: 'Mazarine Pingeot', nom: 'Pingeot', prenom: 'Mazarine'},
    {auteur: 'Marin Fouqué', nom: 'Fouqué', prenom: 'Marin'},
    {auteur: 'Jacques Bénigne Bossuet', nom: 'Bossuet', prenom: 'Jacques Bénigne'},
    {auteur: 'Marguerite Yourcenar', nom: 'Yourcenar', prenom: 'Marguerite'},
    {auteur: 'Serge Tisseron', nom: 'Tisseron', prenom: 'Serge'},
    {auteur: 'Théophile Gautier', nom: 'Gautier', prenom: 'Théophile'},
    {auteur: 'Pierre de Ronsard', nom: 'Ronsard', prenom: 'Pierre de'},
    {auteur: 'Simone de Beauvoir', nom: 'Beauvoir', prenom: 'Simone de'},
    {auteur: 'Jean Giono', nom: 'Giono', prenom: 'Jean'},
    {auteur: 'Marie Darrieussecq', nom: 'Darrieussecq', prenom: 'Marie'},
    {auteur: 'Iegor Gran', nom: 'Gran', prenom: 'Iegor'},
    {auteur: 'Michel Desmurget', nom: 'Desmurget', prenom: 'Michel'},
    {auteur: 'Xavier de la Porte', nom: 'La Porte', prenom: 'Xavier de'},
    {auteur: 'Louis Aragon', nom: 'Aragon', prenom: 'Louis'},
    {auteur: 'Jean de la Bruyère', nom: 'La Bruyère', prenom: 'Jean de'},
    {auteur: 'Judith Perrignon', nom: 'Perrignon', prenom: 'Judith'},
    {auteur: 'Louis-Ferdinand Céline', nom: 'Céline', prenom: 'Louis-Ferdinand'},
    {auteur: 'Honoré de Balzac', nom: 'Balzac', prenom: 'Honoré de'},
    {auteur: 'Gustave Flaubert', nom: 'Flaubert', prenom: 'Gustave'},
    {auteur: 'Odile Weulersse', nom: 'Weulersse', prenom: 'Odile'},
    {auteur: 'Elsa Triolet', nom: 'Triolet', prenom: 'Elsa'},
    {auteur: 'Michel Leiris', nom: 'Leiris', prenom: 'Michel'},
    {auteur: 'Sami Tchak', nom: 'Tchak', prenom: 'Sami'},
    {auteur: 'Henri Barbusse', nom: 'Barbusse', prenom: 'Henri'},
    {auteur: 'Christine Angot', nom: 'Angot', prenom: 'Christine'},
    {auteur: 'Jean-Paul Sartre', nom: 'Sartre', prenom: 'Jean-Paul'},
    {auteur: 'Eric Reinhardt', nom: 'Reinhardt', prenom: 'Eric'},
    {auteur: 'Renée Vivien', nom: 'Vivien', prenom: 'Renée'},
    {auteur: 'Justine Lévy', nom: 'Lévy', prenom: 'Justine'},
    {auteur: 'Victor Hugo', nom: 'Hugo', prenom: 'Victor'},
    {auteur: 'Rebecca Watson', nom: 'Watson', prenom: 'Rebecca'},
    {auteur: 'Stephen King', nom: 'King', prenom: 'Stephen'},
    {auteur: 'Ahmed Sefrioui', nom: 'Sefrioui', prenom: 'Ahmed'},
    {auteur: 'Sofi Oksanen', nom: 'Oksanen', prenom: 'Sofi'},
    {auteur: 'Jean-Philippe Toussaint', nom: 'Toussaint', prenom: 'Jean-Philippe'},
    {auteur: 'Eric Vuillard', nom: 'Vuillard', prenom: 'Eric'},
    {auteur: 'Anne Berest', nom: 'Berest', prenom: 'Anne'},
    {auteur: 'Charles de Gaulle', nom: 'Gaulle', prenom: 'Charles de'},
    {auteur: 'Alice Ferney', nom: 'Ferney', prenom: 'Alice'},
    {auteur: 'Ted Chiang', nom: 'Chiang', prenom: 'Ted'},
    {auteur: 'Nicolas Mathieu', nom: 'Mathieu', prenom: 'Nicolas'},
    {auteur: 'Céline Curiol', nom: 'Curiol', prenom: 'Céline'},
    {auteur: 'Edouard Louis', nom: 'Louis', prenom: 'Edouard'},
    {auteur: 'François-René de Chateaubriand', nom: 'Chateaubriand', prenom: 'François-René de'},
    {auteur: 'George Sand', nom: 'Sand', prenom: 'George'},
    {auteur: 'David Larousserie', nom: 'Larousserie', prenom: 'David'},
    {auteur: 'Georges Perec', nom: 'Perec', prenom: 'Georges'},
]).reduce<{ [l: string]: Array<{ auteur: string, nom: string, prenom?: string }> }>((acc, val) => {
    const lettre = val.nom.charAt(0)
    acc[lettre] = [...(acc[lettre] || []), val]
    return acc
}, {})
Object.values(auteurs).forEach(val => val.sort((a, b) => a.nom.localeCompare(b.nom)))
import ReactDOM from 'react-dom'
import React, {FunctionComponent, useState} from 'react'
import {NanoId} from '../../server/model/Ids'
import {routes} from '../../server/routes'

const VoteComponent: FunctionComponent<{ voteCount: number, textId: NanoId, voted: boolean }> = (props) => {
    const [state, setState] = useState({voteCount: props.voteCount, voted: props.voted})
    function onClick() {
        if (state.voted)
            return
        setState(prevState => ({voteCount: prevState.voteCount + 1, voted: true}))
        const data = new URLSearchParams({textId: props.textId})
        fetch(routes.postVoteText.reverse({}), {
            method: 'post',
            body: data
        })
    }
    return (
        <>
            <button className="fas fa-thumbs-up"
                    onClick={onClick}
                    style={{fontSize: '2.5rem', color: state.voted ? 'var(--color-main)' : 'var(--color-text-light)'}}/>
            &nbsp;
            <span className="text-big">{state.voteCount}</span>
        </>
    )
}
(function () {
    const voteButtonRoot = document.getElementById('vote-button')
    if (!voteButtonRoot)
        return
    const voteCount = Number.parseInt(voteButtonRoot.dataset.voteCount!)
    const textId = voteButtonRoot.dataset.textId as NanoId
    const voted = voteButtonRoot.dataset.voted === 'true'
    ReactDOM.render(<VoteComponent voteCount={voteCount} textId={textId} voted={voted}/>, voteButtonRoot)
})()

import User from './User'
import {Column, Entity, JoinColumn, ManyToOne, PrimaryColumn} from 'typeorm'
import {NanoId} from './Ids'
import Text from './Text'
import {identifier, object, serializable} from 'serializr'

@Entity()
export class Mark {
    @PrimaryColumn('char', {name: 'id', length: 16})
    @serializable(identifier())
    private _id!: NanoId
    @ManyToOne(() => User, {nullable: false})
    @JoinColumn({name: 'contributor_id'})
    @serializable(object(User))
    private _contributor!: User
    @ManyToOne(() => Text, {nullable: false})
    @JoinColumn({name: 'text_id'})
    @serializable(object(Text))
    private _text!: Text
    @Column('text', {name: 'label'})
    @serializable
    private _label!: string
    @Column('text', {name: 'extract'})
    @serializable
    private _extract!: string
    @Column("timestamptz", {name: 'created_at'})
    private _createdAt!: Date;

    get id(): NanoId {
        return this._id
    }

    set id(value: NanoId) {
        this._id = value
    }

    get contributor(): User {
        return this._contributor
    }

    set contributor(value: User) {
        this._contributor = value
    }

    get text(): Text {
        return this._text
    }

    set text(value: Text) {
        this._text = value
    }

    get extract(): string {
        return this._extract
    }

    set extract(value: string) {
        this._extract = value
    }

    get label(): string {
        return this._label
    }

    set label(value: string) {
        this._label = value
    }

    get createdAt(): Date {
        return this._createdAt
    }

    set createdAt(value: Date) {
        this._createdAt = value
    }
}
export enum Focalisation {
    SANS_OBJET = 'SANS_OBJET',
    INTERNE = 'INTERNE',
    EXTERNE = 'EXTERNE',
    OMNISCIENTE = 'OMNISCIENTE',
}

interface FocalisationObj {
    label: string
}

export function focalisation(focalisation: Focalisation): FocalisationObj {
    switch (focalisation) {
        case Focalisation.SANS_OBJET:
            return {label: 'Sans objet'};
        case Focalisation.INTERNE:
            return {label: 'Interne'};
        case Focalisation.EXTERNE:
            return {label: 'Externe'};
        case Focalisation.OMNISCIENTE:
            return {label: 'Omnisciente'};
    }
}
import React, {FormEvent, FunctionComponent} from 'react'
import {typeDiscours, TypeDiscours} from '../../server/model/TypeDiscours'
import {CommunoSelect} from '../edit-text/component/CommunoSelect'
import {SearchInputs} from '../../server/routes'
import {genre, Genre} from '../../server/model/Genre'
import {temps, Temps} from '../../server/model/Temps'
import {entreeProgramme, EntreeProgramme} from '../../server/model/EntreeProgramme'
import {focalisation, Focalisation} from '../../server/model/Focalisation'
import {registre, Registre} from '../../server/model/Registre'
import {mouvement, Mouvement} from '../../server/model/Mouvement'

interface AdvancedSearchProps {
    inputs: SearchInputs

    onChange(inputs: SearchInputs): void

    onSubmit(event: FormEvent): void
}

export const AdvancedSearchComponent: FunctionComponent<AdvancedSearchProps> = props => {
    return (
        <form onSubmit={props.onSubmit} className="row space-items">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <label>Type de discours</label>
                <CommunoSelect options={Object.values(TypeDiscours).sort(((d1, d2) => (typeDiscours(d1).label.localeCompare(typeDiscours(d2).label))))}
                               selected={props.inputs.typeDiscours}
                               isClearable={true}
                               labelFunction={val => typeDiscours(val).label}
                               onChange={(value) => props.onChange({...props.inputs, typeDiscours: value})}/>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <label>Genre</label>
                <CommunoSelect options={Object.values(Genre).sort(((d1, d2) => (genre(d1).label.localeCompare(genre(d2).label))))}
                               selected={props.inputs.genres || []}
                               isClearable={true}
                               labelFunction={val => genre(val).label}
                               onChange={(value) => props.onChange({...props.inputs, genres: value})}/>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <label>Temps dominant</label>
                <CommunoSelect options={Object.values(Temps).sort(((d1, d2) => (temps(d1).label.localeCompare(temps(d2).label))))}
                               selected={props.inputs.temps || []}
                               isClearable={true}
                               labelFunction={val => temps(val).label}
                               onChange={(value) => props.onChange({...props.inputs, temps: value})}/>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <label>Focalisation</label>
                <CommunoSelect options={Object.values(Focalisation).sort(((d1, d2) => (focalisation(d1).label.localeCompare(focalisation(d2).label))))}
                               selected={props.inputs.focalisation}
                               isClearable={true}
                               labelFunction={val => focalisation(val).label}
                               onChange={(value) => props.onChange({...props.inputs, focalisation: value})}/>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <label>Registre</label>
                <CommunoSelect options={Object.values(Registre).sort(((d1, d2) => (registre(d1).label.localeCompare(registre(d2).label))))}
                               selected={props.inputs.registre}
                               isClearable={true}
                               labelFunction={val => registre(val).label}
                               onChange={(value) => props.onChange({...props.inputs, registre: value})}/>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <label>Mouvement</label>
                <CommunoSelect options={Object.values(Mouvement).sort(((d1, d2) => (mouvement(d1).label.localeCompare(mouvement(d2).label))))}
                               selected={props.inputs.mouvement}
                               isClearable={true}
                               labelFunction={val => mouvement(val).label}
                               onChange={(value) => props.onChange({...props.inputs, mouvement: value})}/>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <label>Siècle</label>
                <input type="number"
                       min="-20"
                       max="21"
                       value={props.inputs.siecle || ''}
                       onChange={(event) => props.onChange({...props.inputs, siecle: event.target.value === '' ? undefined : event.target.value})}
                       placeholder="18, 19, 20..."/>
            </div>
            <div className="col-auto align-self-center">
                <button type="submit" className="btn">Rechercher</button>
            </div>
        </form>
    )
}
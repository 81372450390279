import React, {FormEvent, FunctionComponent, MouseEvent, useEffect, useRef, useState} from 'react'
import {routes, SearchInputs} from '../../server/routes'
import User from '../../server/model/User'
import {SearchQueryComponent} from './SearchQueryComponent'
import {CategoryMenu} from './CategoryMenu'
import {AdvancedSearchComponent} from './AdvancedSearchComponent'

interface HeaderProps {
    searchInputs?: SearchInputs
    loggedInUser: User | null
    postLoginRedirect: string | undefined
    favicon: string
}

export const HeaderComponent: FunctionComponent<HeaderProps> = props => {
    const [searchInputs, setSearchInputs] = useState(props.searchInputs || {})
    const [advanced, setAdvanced] = useState(false)
    const [openUserMenu, setOpenUserMenu] = useState(false)
    const userMenuRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        document.addEventListener('click', event => {
            if (!userMenuRef.current!.contains(event.target as HTMLElement)) {
                setOpenUserMenu(false)
            }
        })
        // useEffect is called only on the clients side so we don't display advanced search on the server and on the first client side render.
        // This prevents dom mismatch warnings
        const {query, ...rest} = searchInputs
        setAdvanced(rest && Object.keys(rest).length > 0)
    }, [])
    function submitSearch(event: FormEvent) {
        event.preventDefault()
        window.location.href = routes.getSearch.reverse({}, searchInputs)
    }
    function toggleAdvanced() {
        setAdvanced(!advanced)
        setSearchInputs({query: searchInputs.query}) // Clear advanced filters
    }
    return <div className="container">
        <div className="row justify-content-between align-items-center">
            <div className="col-auto">
                <a className="logo unstyle" href={routes.getHomepage.reverse({})}>
                    <img className="d-inline-block d-md-none" src={props.favicon} style={{width: '2rem', height: '2rem'}} alt="Communotext"/>
                    <div className="d-none d-md-block">
                        <div className="quote">« ... »</div>
                        <div className="name">communo<span>text</span></div>
                    </div>
                </a>
            </div>
            <div className="col col-lg-5">
                <SearchQueryComponent query={searchInputs.query}
                                      onChange={query => setSearchInputs({...searchInputs, query})}
                                      onSubmit={submitSearch}/>
                <button className="btn-link text-small" onClick={toggleAdvanced}>
                    {advanced ? <><i className="fas fa-times"/> Masquer les critères</> : <><i className="fas fa-filter"/> Plus de critères</>}
                </button>
            </div>
            <div className="col-auto d-flex align-items-center">
                {props.loggedInUser ?
                    <div style={{position: 'relative'}}>
                        <div id="user-menu" ref={userMenuRef} onClick={() => setOpenUserMenu(true)}>
                            <div className="d-inline-block text-right">
                                <i className="fas fa-user d-inline-block d-md-none" style={{fontSize: '1.3rem'}}/>
                                <div className="text-light d-none d-lg-block">{props.loggedInUser.email}</div>
                            </div>
                            <i className="fas fa-angle-down" style={{marginLeft: '.5rem'}}/>
                        </div>
                        <div id="user-dropdown" className="text-left" style={{display: openUserMenu ? '' : 'none', position: 'absolute', whiteSpace: 'nowrap'}}>
                            <a className="btn-link" href={routes.logout.reverse({})}>Déconnexion</a>
                        </div>
                    </div> :
                    <a className="btn-link" href={routes.getLogin.reverse({}, {postLoginRedirect: props.postLoginRedirect})}>
                        <i className="fas fa-sign-in-alt d-inline-block d-md-none" style={{fontSize: '1.3rem'}}/>
                        <span className="d-none d-md-inline">Connexion</span>
                    </a>}
            </div>
        </div>
        <div className="space">
            {advanced && <AdvancedSearchComponent inputs={searchInputs}
                                                  onChange={setSearchInputs}
                                                  onSubmit={submitSearch}/>}
            {!advanced && <CategoryMenu/>}
        </div>
    </div>
}
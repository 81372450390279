import ReactDOM from 'react-dom'
import React from 'react'
import {Mark} from '../../server/model/Mark'
import {MarksComponent} from './MarksComponent'
import {deserialize} from 'serializr'

(function () {
    const marksElement = document.getElementById('marks-display')
    if (!marksElement)
        return
    const marks: Mark[] = deserialize(Mark, JSON.parse(document.getElementById('marks')!.innerHTML) as any[])
    ReactDOM.hydrate(<MarksComponent marks={marks} loggedInUser={window.loggedInUser}/>, marksElement)
})()

import {Column, Entity, PrimaryColumn} from "typeorm";
import {NanoId} from "./Ids";
import {Role} from "./Role";
import {identifier, serializable} from 'serializr'

@Entity('users')
export default class User {
    @PrimaryColumn('char', {name: 'id', length: 16})
    @serializable(identifier())
    private _id!: NanoId;
    @Column("text", {name: 'email', nullable: false})
    @serializable
    private _email!: string;
    @Column("text", {name: 'password'})
    private _password: string | undefined;
    @Column("text", {name: 'role', nullable: false})
    @serializable
    private _role!: Role;
    @Column("timestamptz", {name: 'created_at', nullable: false})
    private _createdAt!: Date;

    get id(): NanoId {
        return this._id;
    }

    set id(value: NanoId) {
        this._id = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get password(): string | undefined {
        return this._password;
    }

    set password(value: string | undefined) {
        this._password = value;
    }

    get role(): Role {
        return this._role;
    }

    set role(value: Role) {
        this._role = value;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    set createdAt(value: Date) {
        this._createdAt = value;
    }
}
import {AnalyticsEvent} from '../../server/cookies'

export interface AnalyticsData {
    enabled: boolean
    event: AnalyticsEvent | null
}

const analyticsData = JSON.parse(document.getElementById('analytics_data')!.innerHTML) as AnalyticsData
(window as any)['ga-disable-UA-181804288-1'] = !analyticsData.enabled
gtag('js', new Date());
gtag('config', 'UA-181804288-1', {
    ...(window.loggedInUser ? {user_id: window.loggedInUser.id} : {}),
});
if (analyticsData.event) {
    gtag('event', analyticsData.event.name, analyticsData.event.params)
}
import Select, {Props} from 'react-select'
import React, {ReactElement} from 'react'
import CreatableSelect from 'react-select/creatable'
import {OptionsType, ValueType} from 'react-select/src/types'

export function CommunoSelect<T extends string, Selection extends T | T[] | undefined>(props: {
    options: T[],
    selected: Selection,
    labelFunction: (val: T) => string,
    onChange: (selected: Selection) => void
    className?: string
    closeMenuOnSelect?: boolean
    isClearable?: boolean
}): ReactElement {
    return <Select
        isMulti={Array.isArray(props.selected)}
        placeholder={Array.isArray(props.selected) ? 'Choix multiple...' : 'Choisir...'}
        options={props.options.map(o => ({value: o, label: props.labelFunction(o)}))}
        value={!props.selected ?
            null
            : Array.isArray(props.selected) ?
                props.selected.map(val => ({value: val, label: props.labelFunction(val)})) :
                {value: props.selected as T, label: props.labelFunction(props.selected as T)}}
        onChange={(value: any) => props.onChange(Array.isArray(props.selected) ? (value || []).map((v: any) => v.value) : value?.value)}
        theme={theme => ({ // See https://react-select.com/styles#overriding-the-theme
            ...theme,
            colors: {
                ...theme.colors,
                primary: 'var(--color-main)',
                primary75: 'var(--color-main-light)',
                primary50: 'var(--color-main-light)',
                primary25: 'var(--color-grey-background)',
            }
        })}
        className={props.className}
        isClearable={props.isClearable ?? false}
        noOptionsMessage={input => 'Introuvable'}
        closeMenuOnSelect={props.closeMenuOnSelect}
    />
}
export function CommunoCreatableSelect<T extends string, Selection extends T | T[]>(props: {
    options: T[],
    selected: Selection,
    onChange: (selected: Selection) => void
    formatCreateLabel: (input: string) => string
    className?: string
    closeMenuOnSelect?: boolean
}): ReactElement {
    const charLimit = 40
    return <CreatableSelect
        className={props.className}
        placeholder={Array.isArray(props.selected) ? 'Saisie libre...' : ''}
        isClearable={false}
        formatCreateLabel={input => input + ' - appuyez sur ⏎'}
        isValidNewOption={inputValue => inputValue.length < charLimit}
        noOptionsMessage={({inputValue}) => '❌ Saisie trop longue'}
        isMulti={Array.isArray(props.selected)}
        options={props.options.map(o => ({value: o, label: o}))}
        value={Array.isArray(props.selected) ?
            props.selected.map(val => ({value: val, label: val})) :
            {value: props.selected as T, label: props.selected as T}}
        onChange={(value: any) => props.onChange(Array.isArray(props.selected) ? (value || []).map((v: any) => v.value) : value.value)}
        theme={theme => ({ // See https://react-select.com/styles#overriding-the-theme
            ...theme,
            colors: {
                ...theme.colors,
                primary: 'var(--color-main)',
                primary75: 'var(--color-main-light)',
                primary50: 'var(--color-main-light)',
                primary25: 'var(--color-grey-background)',
            }
        })}
        closeMenuOnSelect={props.closeMenuOnSelect}
    />}



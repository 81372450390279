export enum Genre {
    AUTOBIOGRAPHIE = 'AUTOBIOGRAPHIE',
    POESIE = 'POESIE',
    SONNET = 'SONNET',
    VERS_LIBRE = 'VERS_LIBRE',
    CHANSON = 'CHANSON',
    LETTRE_OUVERTE = 'LETTRE_OUVERTE',
    DISCOURS = 'DISCOURS',
    DISCOURS_DELIBERATIF = 'DISCOURS_DELIBERATIF',
    PAMPHLET = 'PAMPHLET',
    RECIT_DE_GUERRE = 'RECIT_DE_GUERRE',
    MEMOIRES = 'MEMOIRES',
    FRAGMENTS = 'FRAGMENTS',
    THEATRE = 'THEATRE',
    NOUVELLE = 'NOUVELLE',
    NOUVELLE_A_CHUTE = 'NOUVELLE_A_CHUTE',
    ROMAN = 'ROMAN',
    AUTOPORTRAIT = 'AUTOPORTRAIT',
    AUTOFICTION = 'AUTOFICTION',
    JOURNAL = 'JOURNAL',
    TRAGEDIE = 'TRAGEDIE',
    DRAME = 'DRAME',
    DRAME_TRAGIQUE = 'DRAME_TRAGIQUE',
    DRAME_ROMANTIQUE = 'DRAME_ROMANTIQUE',
    ARTICLE = 'ARTICLE',
    TRIBUNE = 'TRIBUNE',
    INTERVIEW = 'INTERVIEW',
    CONTE_PHILOSOPHIQUE = 'CONTE_PHILOSOPHIQUE',
    FABLE = 'FABLE',
    APOLOGUE = 'APOLOGUE',
    PARABOLE = 'PARABOLE',
    ESSAI = 'ESSAI',
    RECIT = 'RECIT',
    ROMAN_AUTOBIOGRAPHIQUE = 'ROMAN_AUTOBIOGRAPHIQUE',
    PARATEXTE = 'PARATEXTE',
    PREFACE = 'PREFACE',
    POSTFACE = 'POSTFACE',
    CRITIQUE = 'CRITIQUE',
    CONTE = 'CONTE',
    JEUNESSE = 'JEUNESSE',
    SERMON = 'SERMON',
    ORAISON_FUNEBRE = 'ORAISON_FUNEBRE',
    PANEGYRIQUE = 'PANEGYRIQUE',
    POLICIER = 'POLICIER',
    SCIENCE_FICTION = 'SCIENCE_FICTION',
    CHRONIQUE = 'CHRONIQUE',
    ROMAN_HISTORIQUE = 'ROMAN_HISTORIQUE',
    EDITORIAL = 'EDITORIAL',
    DIALOGUE = 'DIALOGUE',
    FARCE = 'FARCE',
    CITATION = 'CITATION',
    EPISTOLAIRE = 'EPISTOLAIRE',
    PORTRAIT = 'PORTRAIT',
    PARODIE = 'PARODIE',
    PASTICHE = 'PASTICHE',
    BIOGRAPHIE = 'BIOGRAPHIE',
    VERSET = 'VERSET',
    TEXTE_RELIGIEUX = 'TEXTE_RELIGIEUX',
}

interface GenreObj {
    label: string
}

export function genre(genre: Genre): GenreObj {
    switch (genre) {
        case Genre.POESIE:
            return {label: 'Poésie'}
        case Genre.SONNET:
            return {label: 'Sonnet'}
        case Genre.VERS_LIBRE:
            return {label: 'Vers libre'}
        case Genre.CHANSON:
            return {label: 'Chanson'}
        case Genre.LETTRE_OUVERTE:
            return {label: 'Lettre ouverte'}
        case Genre.DISCOURS:
            return {label: 'Discours'}
        case Genre.DISCOURS_DELIBERATIF:
            return {label: 'Discours délibératif'}
        case Genre.PAMPHLET:
            return {label: 'Pamphlet'}
        case Genre.RECIT_DE_GUERRE:
            return {label: 'Récit de guerre'}
        case Genre.THEATRE:
            return {label: 'Théâtre'}
        case Genre.NOUVELLE:
            return {label: 'Nouvelle'}
        case Genre.NOUVELLE_A_CHUTE:
            return {label: 'Nouvelle à chute'}
        case Genre.ROMAN:
            return {label: 'Roman'}
        case Genre.AUTOBIOGRAPHIE:
            return {label: 'Autobiographie'}
        case Genre.AUTOPORTRAIT:
            return {label: 'Autoportrait'}
        case Genre.AUTOFICTION:
            return {label: 'Autofiction'}
        case Genre.MEMOIRES:
            return {label: 'Mémoires'}
        case Genre.FRAGMENTS:
            return {label: 'Fragments'}
        case Genre.JOURNAL:
            return {label: 'Journal'}
        case Genre.TRAGEDIE:
            return {label: 'Tragédie'}
        case Genre.DRAME:
            return {label: 'Drame'}
        case Genre.DRAME_ROMANTIQUE:
            return {label: 'Drame romantique'}
        case Genre.DRAME_TRAGIQUE:
            return {label: 'Drame tragique'}
        case Genre.ARTICLE:
            return {label: 'Article'}
        case Genre.TRIBUNE:
            return {label: 'Tribune'}
        case Genre.INTERVIEW:
            return {label: 'Interview'}
        case Genre.CONTE_PHILOSOPHIQUE:
            return {label: 'Conte philosophique'}
        case Genre.FABLE:
            return {label: 'Fable'}
        case Genre.APOLOGUE:
            return {label: 'Apologue'}
        case Genre.PARABOLE:
            return {label: 'Parabole'}
        case Genre.ESSAI:
            return {label: 'Essai'}
        case Genre.RECIT:
            return {label: 'Récit'}
        case Genre.ROMAN_AUTOBIOGRAPHIQUE:
            return {label: 'Roman autobiographique'}
        case Genre.PARATEXTE:
            return {label: 'Paratexte'}
        case Genre.PREFACE:
            return {label: 'Préface'}
        case Genre.POSTFACE:
            return {label: 'Postface'}
        case Genre.CRITIQUE:
            return {label: 'Critique'}
        case Genre.CONTE:
            return {label: 'Conte'}
        case Genre.JEUNESSE:
            return {label: 'Littérature jeunesse'}
        case Genre.SERMON:
            return {label: 'Sermon'}
        case Genre.ORAISON_FUNEBRE:
            return {label: 'Oraison funèbre'}
        case Genre.PANEGYRIQUE:
            return {label: 'Panégyrique'}
        case Genre.POLICIER:
            return {label: 'Policier'}
        case Genre.SCIENCE_FICTION:
            return {label: 'Science-fiction'}
        case Genre.CHRONIQUE:
            return {label: 'Chronique'}
        case Genre.ROMAN_HISTORIQUE:
            return {label: 'Roman historique'}
        case Genre.DIALOGUE:
            return {label: 'Dialogue'}
        case Genre.EDITORIAL:
            return {label: 'Éditorial'}
        case Genre.FARCE:
            return {label: 'Farce'}
        case Genre.CITATION:
            return {label: 'Citation'}
        case Genre.EPISTOLAIRE:
            return {label: 'Épistolaire'}
        case Genre.PORTRAIT:
            return {label: 'Portrait'}
        case Genre.PARODIE:
            return {label: 'Parodie'}
        case Genre.PASTICHE:
            return {label: 'Pastiche'}
        case Genre.BIOGRAPHIE:
            return {label: 'Biographie'}
        case Genre.VERSET:
            return {label: 'Verset'}
        case Genre.TEXTE_RELIGIEUX:
            return {label: 'Texte religieux'}
    }
}
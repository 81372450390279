import {ParamsDictionary} from 'express-serve-static-core'
import {NanoId} from './model/Ids'
import {TypeDiscours} from './model/TypeDiscours'
import {Genre} from './model/Genre'
import {Temps} from './model/Temps'
import {EntreeProgramme} from './model/EntreeProgramme'
import {Focalisation} from './model/Focalisation'
import {Registre} from './model/Registre'
import {Mouvement} from './model/Mouvement'

export interface Path<PathParams, QueryParams> {
    pathPattern: string

    reverse(pathParams: PathParams, queryParams?: QueryParams, fragment?: string): string

    _compiler_hack_path_params: PathParams
    _compiler_hack_query_params: QueryParams
}

const pathParamRegex = /:\w+(\(.+\))?/g
function route<PathParams extends ParamsDictionary = {}, QueryParams extends { [key: string]: undefined | string | string[] } = {}>(path: string): Path<PathParams, QueryParams> {
    return {
        pathPattern: path,
        reverse: (pathParams: PathParams, queryParams?: QueryParams, fragment?: string) =>
            path.replace(pathParamRegex, (substring, args) => {
                let paramNameEndIndex = substring.indexOf('(')
                if (paramNameEndIndex == -1)
                    paramNameEndIndex = substring.length
                return pathParams[substring.slice(1, paramNameEndIndex) as keyof (PathParams)]
            }) + (queryParams ? ('?' + Object.entries(queryParams)
                .filter(([name, value]) => value !== undefined)
                .map(([name, value]) => {
                    if (!value)
                        return name
                    // This reproduce the behavior of express regarding arrays in query params. See https://expressjs.com/fr/4x/api.html#req.query
                    if (Array.isArray(value))
                        name += '[]'
                    value = Array.isArray(value) ? value : [value]
                    return value.map(val => name + '=' + encodeURIComponent(val)).join('&')
                })
                .join('&')) : '')
            + (fragment ? '#' + fragment : ''),
        _compiler_hack_path_params: {} as PathParams, // Forces the compiler to compare the structure of PathParams between these routes and handlers
        _compiler_hack_query_params: {} as QueryParams,
    }
}
export const routes = {
    getHomepage: route('/'),
    getText: route<{ textSlug: string, textId: NanoId }>('/texte/:textSlug([\\w-]{0,})-:textId'),
    postPoll: route('/postPoll'),
    getCreateTextBody: route('/new/text/body'),
    getCreateTextFields: route('/new/text/fields'),
    getCreateTextAdds: route('/new/text/adds'),
    getEditTextBody: route<{ textId: NanoId }>('/edit/text/:textId/body'),
    getEditTextFields: route<{ textId: NanoId }>('/edit/text/:textId/fields'),
    getEditTextAdds: route<{ textId: NanoId }>('/edit/text/:textId/adds'),
    postEditText: route('/edit/text'),
    getLogin: route<{}, { postLoginRedirect?: string, email?: string }>('/login'),
    postLogin: route('/login'),
    getRegister: route<{}, { postLoginRedirect?: string }>('/register'),
    postRegister: route('/register'),
    logout: route('/logout'),
    getTextsListRegistre: route<{ registreSlug: string }>('/textes/registres/:registreSlug'),
    getTextsListGenre: route<{ genreSlug: string }>('/textes/genres/:genreSlug'),
    getTextsListTypeDiscours: route<{ typeDiscoursSlug: string }>('/textes/dominantes/:typeDiscoursSlug'),
    getTextsListSiecle: route<{ siecleSlug: SiecleSlug }>('/textes/siecles/:siecleSlug'),
    getTextsListAuteur: route<{ auteur: string }>('/textes/auteurs/:auteur'),
    getTextsListLegacy: route<{ slug: TextsListSlug }>('/textes/:slug(*)'),
    postVoteText: route('/vote-text'),
    getJobs: route<{}, { runningJobClassName?: string }>('/jobs'),
    postJobs: route('/run-job'),
    getSearch: route<{}, SearchInputs>('/search'),
    getMarkText: route<{ textId: NanoId }>('/mark-text/:textId'),
    postMarkText: route<{ textId: NanoId }>('/mark-text/:textId'),
    postRemoveMark: route<{}, { markId: NanoId }>('/remove-mark'),
    getErrorLogTest: route('/test-error-log'),
    getMentionsLegales: route('/mentions-legales'),
    getAPropos: route('/a-propos'),
    getAds: route('/ads.txt'),
    postCreateOrder: route('/api/create-order'),
    postCaptureOrder: route<{}, {}>('/api/capture-order'),
}
export type TextsListSlug =
// Registres
    'martial'
    | 'epiques'
    | 'lyriques'
    | 'burlesque'
    | 'ironiques'
    | 'pessimiste'
    | 'dramatiques'
    | 'realiste'
    | 'satiriques'
    | 'journalistique'
    | 'merveilleux'
    | 'parodique'
    | 'deliberatif'
    | 'didactiques'
    | 'polemiques'
    | 'pathetique'
    | 'tragique'
    | 'epidictique'
    | 'solennel'
    | 'serieux'
    | 'oratoire'
    | 'grotesque'
    | 'nouvelles-fantastiques'
    | 'mystique'
    | 'comique'
    | 'humoristique'
    | 'informatif'
    | 'scientifique'
    | 'farcesque'
    | 'ludique'
    // Genres
    | 'autobiographiques'
    | 'poesie'
    | 'sonnets'
    | 'vers-libres'
    | 'chansons'
    | 'lettres-ouvertes'
    | 'discours'
    | 'discours-deliberatifs'
    | 'pamphlets'
    | 'recits-guerre'
    | 'memoires'
    | 'fragments'
    | 'theatre'
    | 'nouvelles'
    | 'nouvelles-a-chutte'
    | 'romans'
    | 'autoportraits'
    | 'autofictions'
    | 'journaux'
    | 'tragedies'
    | 'drames'
    | 'drames-tragiques'
    | 'drames-romantiques'
    | 'articles'
    | 'tribunes'
    | 'interviews'
    | 'contes-philosophiques'
    | 'fables'
    | 'apologues'
    | 'paraboles'
    | 'essais'
    | 'recits'
    | 'romans-autobiographiques'
    | 'paratextes'
    | 'prefaces'
    | 'postfaces'
    | 'critiques'
    | 'contes'
    | 'jeunesse'
    | 'sermons'
    | 'oraisons-funebres'
    | 'panegyriques'
    | 'policier'
    | 'science-fiction'
    | 'chroniques'
    | 'romans-historiques'
    | 'editorial'
    | 'farces'
    // Type de discours
    | 'narratifs'
    | 'argumentatifs'
    | 'descriptifs'
    | 'explicatifs'
    | 'dialogues'
    // Siècles
    | 'siecle/XVIIe'
    | 'siecle/XVIIIe'
    | 'siecle/XIXe'
    | 'siecle/XXe'
    | 'siecle/XXIe'
export type SiecleSlug =
    'XVIe' |
    'XVIIe' |
    'XVIIIe' |
    'XIXe' |
    'XXe' |
    'XXIe'
export type SearchInputs = {
    query?: string
    typeDiscours?: TypeDiscours
    genres?: Genre[]
    temps?: Temps[]
    focalisation?: Focalisation
    registre?: Registre
    mouvement?: Mouvement
    siecle?: string
}
export enum Narration {
    SANS_OBJET = 'SANS_OBJET',
    TROISIEME_PERSONNE = 'TROISIEME_PERSONNE',
    PREMIERE_PERSONNE = 'PREMIERE_PERSONNE',
}

interface NarrationObj {
    label: string
}

export function narration(narration: Narration): NarrationObj {
    switch (narration) {
        case Narration.SANS_OBJET:
            return {label: 'Sans objet'};
        case Narration.PREMIERE_PERSONNE:
            return {label: '1re personne'};
        case Narration.TROISIEME_PERSONNE:
            return {label: '3e personne'};
    }
}
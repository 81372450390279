import React, {CSSProperties, FunctionComponent, MouseEvent, useEffect, useRef, useState} from 'react'
import Switch from 'react-switch'
import MarkJS from 'mark.js'
import {Mark} from '../../server/model/Mark'
import tippy from 'tippy.js'
import User from '../../server/model/User'
import {routes} from '../../server/routes'
import {Role} from '../../server/model/Role'

export const MarksComponent: FunctionComponent<{ marks: Mark[], loggedInUser: User | null }> = ({marks, loggedInUser}) => {
    const [displayMarks, setDisplayMarks] = useState(true)
    const markjsRef = useRef<MarkJS>()
    const [mainColor, setMainColor] = useState<string | undefined>(undefined)
    useEffect(() => {
        markjsRef.current = new MarkJS('#text-html-content')
        setMainColor(window.getComputedStyle(document.documentElement).getPropertyValue('--color-main').trim())
    }, [])
    useEffect(() => {
        if (displayMarks) {
            for (const mark of marks) {
                markjsRef.current!.mark(mark.extract.replace(/\r?\n|\r/g, ""), {
                    element: 'span',
                    className: [
                        'text-mark-1',
                        'text-mark-2',
                        'text-mark-3',
                        'text-mark-4',
                    ][marks.indexOf(mark) % 4],
                    separateWordSearch: false,
                    acrossElements: true,
                    exclude: ['sup'],
                    each(markElement: Element) {
                        tippy(markElement, {
                            content: mark.label,
                            placement: 'bottom',
                        })
                    }
                })
            }
        } else {
            markjsRef.current!.unmark()
        }
    })
    function markLabelStyle(mark: Mark): CSSProperties | undefined {
        return displayMarks ? {
            textDecoration: 'underline',
            textDecorationThickness: '.4rem',
            textDecorationColor: [
                'var(--color-panel-1)',
                'var(--color-panel-2)',
                'var(--color-panel-3)',
                'var(--color-panel-4)',
            ][marks.indexOf(mark) % 4]
        } : undefined
    }
    function deleteMark(event: MouseEvent, mark: Mark) {
        event.preventDefault()
        if (confirm(`Êtes-vous sur de vouloir supprimer « ${mark.label} » ?`)) {
            fetch(routes.postRemoveMark.reverse({}), {
                method: 'POST',
                body: new URLSearchParams({markId: mark.id})
            }).then(() => window.location.reload())
        }
    }
    return (
        <>
            {marks.length > 0 &&
            <label className="space" style={{cursor: 'pointer', display: 'flex', alignItems: 'center', color: displayMarks ? 'var(--color-main)' : 'var(--color-text)'}}>
                <Switch suppressHydrationWarning={true}
                        onChange={checked => setDisplayMarks(checked)}
                        checked={displayMarks}
                        onColor={mainColor}
                        uncheckedIcon={false}
                        checkedIcon={false}/>
                <span>&nbsp;Repérer dans le texte</span>
            </label> || <div className="text-light">Aucun élément n'a encore été signalé sur ce texte.</div>}
            <ul className="unstyle row space-items">
                {marks.map(mark =>
                    <li className="col-auto" key={mark.id}>
                        <div style={markStyle} className="visible-on-hover-container">
                            <div className="space">
                                <span style={markLabelStyle(mark)}>{mark.label}</span>
                                {loggedInUser?.role === Role.ADMIN && <>&nbsp;&nbsp;<a className="visible-on-hover" style={{color: '#c02d2e', textDecoration: 'none'}} href="#" onClick={event => deleteMark(event, mark)}>suppr.</a></>}
                            </div>
                        </div>
                    </li>)}
            </ul>
        </>
    )
}
const markStyle: CSSProperties = {
    padding: '.75rem',
    background: 'white',
}
export enum Temps {
    PRESENT = 'PRESENT',
    IMPARFAIT = 'IMPARFAIT',
    PASSE_COMPOSE = 'PASSE_COMPOSE',
    PLUS_QUE_PARFAIT = 'PLUS_QUE_PARFAIT',
    PASSE_SIMPLE = 'PASSE_SIMPLE',
    IMPERATIF = 'IMPERATIF',
    CONDITIONNEL = 'CONDITIONNEL',
    FUTUR = 'FUTUR',
    SUBJONCTIF_PRESENT = 'SUBJONCTIF_PRESENT',
    SUBJONCTIF_IMPARFAIT = 'SUBJONCTIF_IMPARFAIT',
    SUBJONCTIF_PLUS_QUE_PARFAIT = 'SUBJONCTIF_PLUS_QUE_PARFAIT',
}

interface TempsObj {
    label: string
}

export function temps(temps: Temps): TempsObj {
    switch (temps) {
        case Temps.PRESENT:
            return {label: 'Présent'}
        case Temps.IMPARFAIT:
            return {label: 'Imparfait'}
        case Temps.PASSE_COMPOSE:
            return {label: 'Passé composé'}
        case Temps.PLUS_QUE_PARFAIT:
            return {label: 'Plus que parfait'}
        case Temps.PASSE_SIMPLE:
            return {label: 'Passé simple'}
        case Temps.IMPERATIF:
            return {label: 'Impératif'}
        case Temps.CONDITIONNEL:
            return {label: 'Conditionnel'}
        case Temps.FUTUR:
            return {label: 'Futur'}
        case Temps.SUBJONCTIF_PRESENT:
            return {label: 'Subjonctif présent'}
        case Temps.SUBJONCTIF_IMPARFAIT:
            return {label: 'Subjonctif imparfait'}
        case Temps.SUBJONCTIF_PLUS_QUE_PARFAIT:
            return {label: 'Subjonctif plus-que-parfait'}
    }
}

import React, {FormEvent, FunctionComponent, useState} from 'react'

interface SearchQueryProps {
    query: string | undefined

    onChange(query: string): void

    onSubmit(event: FormEvent): void
}

export const SearchQueryComponent: FunctionComponent<SearchQueryProps> = props => {
    const [focused, setFocused] = useState(false)
    return (
        <form onSubmit={event => props.onSubmit(event)} style={{display: 'flex', alignItems: 'center'}}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}>
            <div style={{flexGrow: 1}}>
                <input value={props.query || ''}
                       onChange={event => props.onChange(event.target.value)}
                       placeholder="thème, notion littéraire, genre, auteur, ..."/>
            </div>
            <button className="fas fa-search" style={{color: focused ? 'var(--color-main)' : '#dedede', fontSize: '1.5rem', marginLeft: '.2em'}} type="submit"/>
        </form>
    )
}